<template>
    <b-card title="Asset Recon Summary">
        <b-form-group label="Region:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-4">
            <b>{{ region }}</b>
        </b-form-group>

        <b-form-group label="Area:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-2">
            <b>{{ area }}</b>
        </b-form-group>

        <b-form-group v-show="company !== '-'" label="Company:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
            class="mt-2">
            <b>{{ company }}</b>
        </b-form-group>

        <b-form-group label="Asset Recon Form:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-2">
            <b>{{ fileName }}</b>
        </b-form-group>

        <b-form-group label="Total Rows:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-2">
            <b>{{ totalRowsProcessed.toLocaleString() }}</b>
        </b-form-group>

        <b-form-group label="Total Valid Rows:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-2"
            description="* Only valid rows are included in asset recon processing">
            <b>{{ totalValidRowsProcessed.toLocaleString() }}</b>
        </b-form-group>

        <b-form-group label="Total Invalid Rows:" label-cols-sm="2" label-class="pt-0" label-align-sm="right" class="mt-2"
            description="* Invalid rows could mean invalid format or non-existent entry of asset code, branch code or employee no">
            <b>{{ totalInvalidRowsProcessed.toLocaleString() }}</b>
        </b-form-group>
    </b-card>
</template>

<script>
// Others
import _ from 'lodash';

export default {
    name: 'asset-recon-processed-summary',
    props: {
        assets: {
            type: Array,
            required: true,
        },
        reconParams: {
            type: Object,
            required: true,
        },
        fileName: {
            type: String,
            required: true
        }
    },
    computed: {
        region() {
            return this.reconParams && this.reconParams.region ? this.reconParams.region : '-';
        },
        area() {
            return this.reconParams && this.reconParams.area ? this.reconParams.area : '-';
        },
        company() {
            return this.reconParams && this.reconParams.company.id != null ? this.reconParams.company.name : '-';
        },
        totalRowsProcessed() {
            return !_.isEmpty(this.assets) ? _.size(this.assets) : 0;
        },
        totalValidRowsProcessed() {
            if (!_.isEmpty(this.assets)) {
                let validRows = _.filter(this.assets, o => {
                    return o.status === 'VALID';
                });
                return _.size(validRows);
            }
            return 0;
        },
        totalInvalidRowsProcessed() {
            if (!_.isEmpty(this.assets)) {
                let invalidRows = _.filter(this.assets, o => {
                    return o.status === 'INVALID';
                });
                return _.size(invalidRows);
            }
            return 0;
        }
    }
}

</script>

<style scoped></style>